import HomePage from '../pages/HomePage.tsx';
import { fetchCountryCode } from '../services/locationService.js';

var part2 = fetchCountryCode();


export const HP = () => {
    
    //var appid = 'QOL_POP_' + sessionStorage.getItem('country_code');
    
    return (
        <>
           <HomePage />
        </>
    )
}