import axios from 'axios';

export const fetchCountryCode = async () => {
    try {
        if (!sessionStorage.getItem('APP_PLATFORM_ID')) {
            const response = await axios.get('https://ipapi.co/json/');
            // console.log("response.data.country_code ", response.data.country_code);

            sessionStorage.setItem('country_code', response.data.country_code);
            sessionStorage.setItem("APP_PLATFORM_ID", 'QOL_POP_' + response.data.country_code);

            return response.data.country_code;
        }

    } catch (error) {
        console.error("Error fetching the country code:", error);
        return "US";
    }
};
